@import "./assets/styles/squareinsurance.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-datepicker table td {
  padding: 3px;
}

.p-datepicker table td > span {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 13px;
}

.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #fff;
  background: #20c997;
  font-weight: 500;
}

.p-datepicker table td > span:focus{
  display: none;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #495057;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 600;
  padding: 0 10px;
}

.p-datepicker .p-datepicker-header {
  padding: 3px 0;
}

span.error-msg {
  font-size: 12px;
  color: #dc3545;
  font-weight: 500;
}

.main-error-border {
  border-color: #dc3545 !important;
}

.input_group .css-1u9des2-indicatorSeparator {
  display: none;
}