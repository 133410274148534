@charset "UTF-8";
:root {
    --main_primary: #10498a;
    --main_secondary: #03a9f4;
    --main_secondary_dark: #0589c6;
    --main_text: #3c3c3c;
    --main_light_gray: #989898;
    --main_body_text_color: #4b4b4b;
    --color_bs_pink: #d63384;
    --color_bs_red: #dc3545;
    --color_bs_orange: #fd7e14;
    --color_bs_yellow: #ffc107;
    --color_bs_green: #198754;
    --color_bs_teal: #20c997;
    --color_bs_dark_teal: #1aac80;
    --color_bs_cyan: #0dcaf0;
    --color_bs_white: #fff;
    --color_bs_gray: #6c757d;
    --color_bs_gray_dark: #343a40;
    --text_hindi: #e1aa03;
    --main_op_back: #c1d0e1;
    --main_black: #1a1a1a;
    --main_green_color: #5BC2AB;
    --main_claim_back: #e4eef9;
    --ckyc_color: #ffc10730;
    --main_light_green: #47D990;
    --color_bs_purpal: #fcefef;
    --main_text_black: #111;
    --color_border_purpal: #fcefef;
    --main_secondary-bg: #00a0e3;
    --main_light_sky_bg: #2cb2b31a;
    --color_bs_red_light: #dc354639
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-ExtraBold.eot);
    src: url(../fonts/Poppins-ExtraBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraBold.woff2) format('woff2'), url(../fonts/Poppins-ExtraBold.woff) format('woff'), url(../fonts/Poppins-ExtraBold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Black.eot);
    src: url(../fonts/Poppins-Black.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Black.woff2) format('woff2'), url(../fonts/Poppins-Black.woff) format('woff'), url(../fonts/Poppins-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Bold.eot);
    src: url(../fonts/Poppins-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Bold.woff2) format('woff2'), url(../fonts/Poppins-Bold.woff) format('woff'), url(../fonts/Poppins-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-ExtraLight.eot);
    src: url(../fonts/Poppins-ExtraLight.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraLight.woff2) format('woff2'), url(../fonts/Poppins-ExtraLight.woff) format('woff'), url(../fonts/Poppins-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Medium.eot);
    src: url(../fonts/Poppins-Medium.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Medium.woff2) format('woff2'), url(../fonts/Poppins-Medium.woff) format('woff'), url(../fonts/Poppins-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Regular.eot);
    src: url(../fonts/Poppins-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Regular.woff2) format('woff2'), url(../fonts/Poppins-Regular.woff) format('woff'), url(../fonts/Poppins-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Light.eot);
    src: url(../fonts/Poppins-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Light.woff2) format('woff2'), url(../fonts/Poppins-Light.woff) format('woff'), url(../fonts/Poppins-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-Thin.eot);
    src: url(../fonts/Poppins-Thin.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Thin.woff2) format('woff2'), url(../fonts/Poppins-Thin.woff) format('woff'), url(../fonts/Poppins-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: poppins;
    src: url(../fonts/Poppins-SemiBold.eot);
    src: url(../fonts/Poppins-SemiBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-SemiBold.woff2) format('woff2'), url(../fonts/Poppins-SemiBold.woff) format('woff'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap
}


html {
    font-family: poppins, sans-serif !important
}

body {
    font-family: poppins !important;
    line-height: 24px;
    letter-spacing: .4px;
    color: #10498a;
    background-color: #fff;
    font-size: 15px;
    color: #4b4b4b;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    padding: 0 !important
}

a:focus,
a:focus-visible,
button:focus,
button:focus-visible,
input:focus,
input:focus-visible {
    outline: 0 !important;
    box-shadow: none !important;
    text-decoration: none !important;
}

.form-select:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none
}

li,
ul {
    margin: 0;
    padding: 0;
    list-style: none
}

a,
a:hover {
    text-decoration: none !important;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -ms-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out
}

img {
    width: auto;
    max-width: 100%
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px
}

::-webkit-scrollbar-track {
    background: #f1f1f1
}

::-webkit-scrollbar-thumb {
    background: #10498a
}

::-webkit-scrollbar-thumb:hover {
    background: #10498a
}

.overflow_hiddenn {
    overflow: hidden;
}


/* ********************************************Custom_loder_css_end***************************************** */

.table_csutome_content {
    padding: 15px 5px;
    box-shadow: inherit !important;
    margin-top: 0px !important;
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
}

.table_csutome_content p,
.table_csutome_content span {
    margin: 0;
    font-size: 12px !important;
}

.timeline-item {
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
    padding: 12px;
    margin: 0 auto;
    width: auto;
    min-height: 98px;
    margin-bottom: 25px;
}

.animated-background {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: #eeeeee;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.background-masker {
    background: #fff;
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.outlined .background-masker {
    border: 1px solid #ddd;
}

.outlined:hover .background-masker {
    border: none;
}

.outlined:hover .background-masker:hover {
    border: 1px solid #ccc;
    z-index: 1;
}

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
    top: 0;
    left: 40px;
    right: 0;
    height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px;
}

.background-masker.header-bottom {
    top: 18px;
    height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
    top: 24px;
    height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
    width: auto;
    left: 590px;
    right: 0;
}

.background-masker.subheader-right {
    left: 490px;
}

.background-masker.subheader-bottom {
    top: 30px;
    height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 6px;
}

.background-masker.content-top {
    height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
    width: auto;
    left: 380px;
    right: 0;
    top: 60px;
    height: 8px;
}

.background-masker.content-second-line {
    top: 68px;
}

.background-masker.content-second-end {
    left: 550px;
    top: 74px;
}

.background-masker.content-third-line {
    top: 82px;
}

.background-masker.content-third-end {
    left: 300px;
    top: 88px;
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}


@media (max-width: 767px) {

    .table_csutome_content p,
    .table_csutome_content span {
        margin: 0;
        font-size: 10px !important;
    }
}

/* ********************************************Custom_loder_css_end***************************************** */


/* ************************custom_loader********************************** */

.cs_custom_lodar {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999999999999999;
    background-color: rgb(5 0 74 / 33%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.cs_custom_lodar .inner_div {
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    background: #fff;
    border-radius: 50%;
    padding: 6px;
    margin: auto;
    top: 43%;
    position: absolute;
    right: 0;
    left: 0;
}

.cs_custom_lodar .inner_div .out_icon {
    animation: 5s linear infinite rotate_with_scale;
    -webkit-animation: 5s linear infinite rotate_with_scale;
}

.cs_custom_lodar .inner_div .out_icon img {
    width: 80px;
}

.cs_custom_lodar .inner_div .inner_icon {
    position: absolute;
    width: 38px;
    /* animation: 2s linear infinite flip_with_scale;
    -webkit-animation: 2s linear infinite flip_with_scale; */
}

@keyframes flip_with_scale {
    0% {
        transform: perspective(400px) scaleX(1);
        -webkit-transform: perspective(400px) scaleX(1);
        -moz-transform: perspective(400px) scaleX(1);
        -ms-transform: perspective(400px) scaleX(1);
        -o-transform: perspective(400px) scaleX(1);
    }

    100% {
        transform: perspective(400px) scaleX(-1);
        -webkit-transform: perspective(400px) scaleX(-1);
        -moz-transform: perspective(400px) scaleX(-1);
        -ms-transform: perspective(400px) scaleX(-1);
        -o-transform: perspective(400px) scaleX(-1);
    }
}

@keyframes rotate_with_scale {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }

    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
}

/* ************************custom_loader********************************** */

/* ************************custom_calender********************************** */
#ui-datepicker-div {
    border: none;
    width: 100%;
    max-width: 350px;
    box-shadow: 0px 0px 30px 0px #c1d0e1;
    background-color: #fff;
    border-radius: 5px;
    z-index: 99999999999999 !important;
}

#ui-datepicker-div .ui-datepicker-title {
    margin: 0 15px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ui-datepicker-div .ui-datepicker-header {
    position: relative;
    padding: 4px;
    background: var(--main_secondary);
    border: none !important;
}

#ui-datepicker-div .ui-datepicker-header .ui-corner-all {
    display: none !important;
}

#ui-datepicker-div select.ui-datepicker-month,
#ui-datepicker-div select.ui-datepicker-year {
    width: 100px;
    padding: 7px 15px;
    font-size: 13px;
    font-weight: 500;
    border: none;
    margin: 0 5px;
    border-radius: 3px;
    text-align: center;
}

#ui-datepicker-div select.ui-datepicker-month:focus,
#ui-datepicker-div select.ui-datepicker-year:focus {
    outline: none !important;
    box-shadow: none !important;
}

#ui-datepicker-div .ui-datepicker-calendar th {
    padding: 5px 3px !important;
    text-align: center;
    border: 0;
    font-size: 12px;
    color: var(--color_bs_orange);
}

#ui-datepicker-div .ui-datepicker-calendar th.ui-datepicker-week-end {
    color: var(--color_bs_red);
}

#ui-datepicker-div .ui-datepicker-calendar tr td {
    padding: 2px !important;
}

#ui-datepicker-div .ui-state-default,
#ui-datepicker-div .ui-widget-content .ui-state-default,
#ui-datepicker-div .ui-widget-header .ui-state-default,
#ui-datepicker-div .ui-button,
#ui-datepicker-div html .ui-button.ui-state-disabled:hover,
#ui-datepicker-div .ui-button.ui-state-disabled:active {
    background-image: none !important;
    font-weight: normal;
    color: #111;
    background: #fff;
    text-align: center;
    border-radius: 2px;
    font-size: 17px;
    width: 41.8px !important;
    height: 38px;
    margin: 2px;
    box-shadow: 0px 0px 3px 0px #c1d0e1;
    display: block;
    line-height: 38px;
}

#ui-datepicker-div .ui-datepicker-days-cell-over.ui-datepicker-today a.ui-state-highlight {
    background: #20c997;
    color: #fff;
}

#ui-datepicker-div .ui-datepicker-calendar tr td a.ui-state-active,
#ui-datepicker-div .ui-datepicker-calendar tr td a:hover {
    background: #20c997;
    color: #fff;
}

.ui-state-disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* ************************custom_calender********************************** */

.main_custom_popup {
    position: fixed;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    background: #28354f5c;
    left: 0;
    top: 0;
    padding: 20px;
    margin: auto;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}

.main_custom_popup .main_custom_popup_inner {
    border: 1px solid #ddd;
    border-radius: 10px;
    height: auto;
    position: relative;
    background: #fff;
    width: 350px;
    margin: auto;
    margin-top: 0;
    box-shadow: 0px 0px 10px 0px var(--main_primary)6b;
    overflow: hidden;
}

#login_popup_main.main_custom_popup .main_custom_popup_inner {
    width: 100%;
    max-width: 450px;
    background-image: linear-gradient(45deg, rgb(227 218 255) 0, rgb(232 225 255) 21%, rgb(236 234 255) 21%, rgb(234 232 255) 30%, rgb(226 231 255) 30%, rgb(241 243 255) 35%, rgb(226 237 255) 35%, rgb(248 251 255) 51%, rgb(248 252 255) 51%, rgb(239 248 255) 72%, rgb(247 245 255) 72%, rgb(243 242 255) 81%, rgb(237 238 255) 81%, rgb(236 253 255) 100%);
    margin: auto;
}

#view_all_products.main_custom_popup .main_custom_popup_inner {
    width: 100%;
    max-width: 750px;
    margin: auto;
}

#view_all_products.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
    max-height: 450px !important;
    padding: 5px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop {
    position: absolute;
    right: 21px;
    top: 10px;
    cursor: pointer;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop img {
    width: 15px !important;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_header {
    font-size: 20px;
    font-weight: 600;
    color: #10498a;
    padding: 11px 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
    padding: 5px 20px 15px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 320px;
}

.main_custom_popup .main_custom_popup_inner.custom_popup_width .main_custom_popup_inner_body {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: none;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body::-webkit-scrollbar {
    width: 0px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data {
    padding-bottom: 30px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login img {
    width: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .login_text_title {
    font-size: 22px;
    font-weight: 600;
    color: #00BCD4;
    line-height: 31px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details a {
    background: #00BCD4;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600;
    padding: 10px 15px;
    line-height: 18px;
    box-shadow: 0px 0px 10px 0px #00bcd48c;
    margin: 0 20px;
}

#quote_products.main_custom_popup .main_custom_popup_inner {
    width: 100%;
    max-width: 750px;
    margin: auto;
}

#quote_products.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
    max-height: 450px !important;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer {
    display: flex;
    align-items: center;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_media,
#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media {
    width: 50%;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media {
    padding-left: 20px;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .input_group {
    margin-bottom: 20px !important;
    display: block;
    width: 100%;
    position: relative;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .input_group .form_control:focus {
    box-shadow: none;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .input_group .form_control{
    appearance: none !important;
    height: 50px;
    font-size: 14px;
    border: 1px solid #9f9f9f;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 0px 13px;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .subbutton {
    height: 50px;
    background: #0a66c3;
    outline: none;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .bottom_description {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px;
    line-height: 16px;
    text-align: left;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .custom_whatapps,
#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .know-btn .check_box_list .custom_whatapps label {
    font-size: 12px;
    color: var(--main_body_text_color);
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-top: 10px;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .custom_whatapps label img {
    width: 16px;
    margin-right: 7px;
}

#quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media .custom_whatapps .form-check {
    margin: 0px 0 0 10px;
    min-height: auto;
}


.successMessage_card {
    text-align: center;
    border: 1px solid var(--main_claim_back);
    background: #00a0e30d;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
}
.successMessage_card h1 {
    color: #111;
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 600;
    margin: 10px 0px 5px 0px;
}
.success_inner_card .checkmark {
    font-size: 24px;
    color: #fff;
    background: #28a745;
    min-width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    border-radius: 10px;
}

.successMessage_card p {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
}


.cs_main_insurance_products_inner {
    background-color: #fff;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid var(--main_claim_back);
    padding: 20px 20px 0px 20px;
    border-radius: 10px;
    background: #00a0e30d;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block h4 {
    margin: 0 0 5px;
    font-size: 14px;
    color: #111;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block ul {
    padding: 0px;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block ul li {
    text-align: center;
    position: relative;
    width: calc(100% / 6 - 5px);
    display: inline-block;
    vertical-align: top;
    margin: 10px 0;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block ul li {
    border-right: 1px solid var(--main_claim_back);
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block ul li:last-child {
    border-right: none;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block a span {
    display: block;
    margin-bottom: 5px;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block a span img {
    width: 36px;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block a small {
    font-size: 10px;
    font-weight: 500;
    display: block;
    transition: .5s ease-in-out;
    color: #3c3c3c;
    line-height: 18px;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block a small b {
    font-weight: 500;
    display: block;
    font-size: 12px;
    color: #10498a;
    line-height: 16px;
}

.cs_main_insurance_products_inner .cs_scrol_box .home_products_block a abbr {
    top: -15px;
    background: #2cb2b3;
    color: #fff;
    padding: 0 8px;
    border-radius: 8px 1px;
    font-size: 10px;
    letter-spacing: .5px;
    position: absolute;
    height: 14px;
    line-height: 14px;
    left: 0;
    right: 0;
    display: inline-table;
    margin: 0 auto;
}

/* ******************************************square_header_css_start**************************************** */

.square_header {
    position: relative;
    z-index: 9;
    background-color: #fff;
}

.square_header.is-sticky {
    position: fixed;
    animation: slideDown 0.35s ease-out;
    background: #fff;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.square_header .header_static {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.square_header .header_static .header-mobile {
    display: none;
}

.square_header .header_static .contact-device {
    display: none;
}

.square_header .header_static .logo_section {
    width: 140px;
}

.square_header .header_static .logo_section img {
    width: 130px;
}

.square_header .header_static .main_menu_inner {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
}

.square_header .header_static .main_menu_inner .top_list_menu {
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-end;
    margin-top: 0px;
    width: 100%;
}

.square_header .header_static .main_menu_inner .top_list_menu a {
    font-size: 12px;
    margin-left: 20px;
    color: #3c3c3c;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.square_header .header_static .main_menu_inner .top_list_menu a:first-child {
    margin-left: 0;
}

.square_header .header_static .main_menu_inner .top_list_menu img {
    width: 16px;
    margin-right: 6px;
}

.square_header .header_static .main_menu_inner .top_list_menu a:hover {
    color: #10498a;
}

.square_header .header_static .main_menu_inner .top_list_menu a.last_app_lo img {
    margin-right: 0;
}

.square_header .header_static .main_menu_inner .main_menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.square_header .header_static .main_menu_inner .main_menu nav ul {
    display: flex;
    margin-bottom: 0px;
    line-height: 29px;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li>a {
    display: block;
    padding: 5px 15px;
    color: #111;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 0;
    transition: 0.5s;
    position: relative;
    cursor: pointer;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li.menu-view {
    display: none;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight {
    border: 1px solid #10498a;
    border-radius: 5px;
    height: 35px;
    line-height: 28px;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight:hover {
    background-color: #10498a;
    color: #fff;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li>a small.down_icon {
    border-bottom: 2px solid #111;
    border-right: 2px solid #111;
    width: 8px;
    height: 8px;
    display: inline-block;
    transform: rotate(45deg);
    position: relative;
    top: -3px;
    margin-left: 4px;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a small.down_icon {
    border-color: #10498a;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a {
    color: #10498a;
}

.square_header .header_static .main_menu_inner .main_menu .login_box {
    margin-left: 10px;
}

.square_header .header_static .main_menu_inner .main_menu .login_box button {
    width: 70px;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
    background-image: linear-gradient(110deg, #00a0e3 0%, #10498a 100%);
    border: none;
    height: 35px;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    z-index: 1;
}

.square_header .header_static .main_menu_inner .main_menu .login_box button:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #10498a;
    border-radius: 5px;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
    transition: all 0.3s ease;
}

.square_header .header_static .main_menu_inner .main_menu .login_box button:hover:after {
    left: 0;
    width: 100%;
    border-radius: 5px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu {
    position: absolute;
    max-width: 100%;
    padding: 0;
    background: #fff;
    border-radius: 4px;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 9;
    top: 50px;
    margin: 0 auto;
    width: 95%;
    border: 1px solid var(--main_claim_back);
    border-radius: 10px;
    overflow: hidden;
    min-height: 412px;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    height: 0px;
    display: none;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li:hover .mega_menu {
    opacity: 1;
    top: 50px;
    display: block;
    height: auto;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu {
    position: relative;
    width: 100%;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu>ul {
    width: 100%;
    padding: 20px;
    margin: 0px;
    display: block !important;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li {
    position: static;
    display: block;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    border: 1px solid var(--main_claim_back);
    border-radius: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:last-child p {
    margin-bottom: 0px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p a {
    color: #111;
    font-size: 14px;
    font-weight: 500;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p a img {
    width: 30px;
    margin-right: 10px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active p,
.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover p {
    background: var(--main_claim_back);
    border-color: var(--main_claim_back);
    color: #000;
    transition: 0.5s ease-in-out;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active p a,
.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover p a {
    color: #000;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p small.plus_down_minus_icon {
    border-bottom: 2px solid #111;
    border-right: 2px solid #111;
    width: 8px;
    height: 8px;
    display: inline-block;
    transform: rotate(-45deg);
    position: relative;
    top: 1px;
    margin-left: 0px;
    font-size: 0px;
    transition: 0.5s ease-in-out;
    display: none;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active p small.plus_down_minus_icon,
.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover p small.plus_down_minus_icon {
    border-color: #000;
    transition: 0.5s ease-in-out;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu {
    background-color: #fff;
    padding: 0px;
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 9;
    overflow-x: hidden;
    position: absolute;
    width: calc(100% - 280px);
    top: 0px;
    right: 0;
    display: none;
    height: 100%;
    padding: 20px 10px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu.block-view {
    display: block;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-part {
    width: 100%;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block {
    position: relative;
    height: 100%;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block .megamenu-btn-align {
    position: absolute;
    bottom: 0px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block .megamenu-btn-align a {
    display: inline-flex;
    font-size: 14px;
    color: #fff;
    background: #10498a;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 8px 15px;
    font-weight: 400;
    letter-spacing: 0.2px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block .megamenu-btn-align a:hover {
    background-color: var(--main_secondary-bg);
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-part h2 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
    margin-bottom: 10px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-part h2 a {
    color: #000;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li.active .shop-mega-menu,
.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li:hover .shop-mega-menu {
    display: flex;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li.active .shop-mega-menu {
    display: block;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul {
    width: 100%;
    padding: 0;
    /* overflow: initial; */
    display: flex;
    flex-wrap: wrap;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li {
    width: calc(100% / 3);
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li a {
    color: #111;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
    display: block;
    text-transform: capitalize;
    font-weight: 400;
    padding: 4px 0px;
    margin: 5px 0px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li:first-child a {
    margin-top: 0px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li:last-child a {
    margin-bottom: 0px;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li a:hover {
    color: #00a0e3;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu {
    position: absolute;
    min-width: 220px;
    padding: 10px 0px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    left: inherit;
    right: inherit;
    z-index: 9;
    top: 50px;
    height: 0px;
    display: none;
    visibility: hidden;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li:hover .sub_menu {
    top: 50px;
    display: block;
    height: auto;
    visibility: visible;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a {
    color: #111;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 0;
    display: block;
    text-transform: capitalize;
    font-weight: 400;
    padding: 7px 10px;
}

.square_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a:hover {
    color: #009687;
}

.square_header .header_static .main_menu_inner #menu_open_btn {
    display: none;
}

.square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .mega-menu-part p {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.header_sidebar{
    width: 100%;
    min-height: 100vh;       
    position: fixed;
    top: 0;
    z-index: 999;
    right: -100%;
}

.header_sidebar .headerbarInner {
    width: 100%;
    max-width: 400px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-left: 1px solid rgba(0, 0, 0, .2);
    background-color: #fff;
    position: relative;
    margin-left: auto;

}

.header_sidebar::before {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: #010a3c73;
    content: "";
}

.header_sidebar.active{
    right: 0;
}

.header_sidebar .sd_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px;
}

.header_sidebar .sd_header img {
    width: 120px;
}

.sidebar-overlay{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}

.header_sidebar .sd_body{
    max-height: calc(100vh - 80px);
    overflow-x: hidden;
    padding: 16px 30px;
}

.header_sidebar .sd_body::-webkit-scrollbar {
    width: 0px;
}

.header_sidebar .sd_body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}

.header_sidebar .sd_body ul li{
    list-style: none;
    margin-bottom: 8px;
}

.header_sidebar .sd_body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.d-block-desktop {
    display: block;
}


.boxes-menu img {
    width: 25px;
}

button.boxes-menu {
    height: auto !important;
    padding: 0px !important;
    background: transparent;
    margin-left: 25px;
    border: none;
}

button.boxes-menu:focus {
    outline: none
}

.offcanvas-logo img {
    width: 100px;
}

.offcanvas-body p {
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 18px;
}

ul.canvas-ul {
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
}

ul.canvas-ul li {
    padding: 5px 5px;
    border-bottom: 1px solid var(--main_claim_back);
}

.offcanvas-header button.btn-close {
    position: absolute;
    right: 37px;
    top: 15px;
    width: 10px;
    height: 10px;
    border-radius: 100% !important;
}

.offcanvas-header {
    padding-bottom: 0;
}

ul.canvas-ul li:last-child {
    border-bottom: none;
}

ul.canvas-ul li a {
    color: #111;
    font-size: 13px;
    display: inline-block;
}

ul.canvas-ul li:last-child a {
    padding-bottom: 0px;
}

.social_media h3 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.social_media span {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
}

.social_media span a img {
    width: 20px;
    margin-right: 15px;
}

.social_media {
    background: #0a66c3;
    padding: 15px;
    border-radius: 5px;
}

.offcanvas {
    background-image: linear-gradient(45deg, rgb(227 218 255) 0, rgb(232 225 255) 21%, rgb(236 234 255) 21%, rgb(234 232 255) 30%, rgb(226 231 255) 30%, rgb(241 243 255) 35%, rgb(226 237 255) 35%, rgb(248 251 255) 51%, rgb(248 252 255) 51%, rgb(239 248 255) 72%, rgb(247 245 255) 72%, rgb(243 242 255) 81%, rgb(237 238 255) 81%, rgb(236 253 255) 100%);
}
.offcanvas-header,
.offcanvas-body {
    padding: 1rem 2rem;
}

.modal-content {
    border: none;
}

.mega-menu-ul {
    border-bottom: 1px solid var(--main_claim_back);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.square_header .menubar_row .all_menu_block .menu_row .login_box {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.square_header .menubar_row .all_menu_block .menu_row .login_box button {
    border: none;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
}

.square_header .menubar_row .all_menu_block .menu_row .login_box .button_menu_mobile {
    margin-left: 15px;
    cursor: pointer;
    display: none;
}

.square_header .menubar_row .all_menu_block .menu_row .login_box .button_menu_mobile img {
    width: 25px;
}

.square_header.fixed .menubar_row .all_menu_block .top_list_menu {
    display: none;
}

.square_header .menubar_row .mobile_menu_open {
    padding: 0;
    width: 20px;
    border: none;
    background-color: transparent;
    display: none;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist {
    display: none;
    margin-top: 15px;
    border: none;
    padding-top: 15px;
    padding-right: 20px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .hm_header_title {
    font-size: 18px;
    font-weight: 600;
    color: #03a9f4;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .hm_header_title img {
    width: 23px;
    margin-right: 7px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box {
    display: flex;
    justify-content: space-between;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box a {
    border: 1px solid #989898;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(100% / 3 - 10px);
    color: #3c3c3c;
    font-size: 14px;
    text-align: center;
    padding: 10px 10px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box a span {
    display: block;
    margin-top: 8px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box a img {
    width: 45px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .support_call_number {
    margin-top: 30px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .support_call_number a {
    font-size: 14px;
    padding: 7px 0;
    color: #3c3c3c;
    border: none;
    display: flex;
    align-items: center;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .support_call_number a img {
    width: 20px;
    margin-right: 7px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .header_footer_menu {
    display: flex;
    width: 100%;
    justify-content: center;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .header_footer_menu a {
    margin: 0 10px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .header_footer_menu a img {
    width: 145px;
    background: #10498a;
    border-radius: 11px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 20px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu img {
    width: 100px;
}

.square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu .close_menu_btn {
    cursor: pointer;
    font-size: 24px;
    width: 18px;
}

.mobile_menu_bottom {
    margin: 0;
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: #fff;
    bottom: 0;
    z-index: 999;
    box-shadow: 0px 0px 30px 10px #00000052;
    left: 0;
    height: 70px;
    padding: 6px 0;
}

.mobile_menu_bottom .menu__item {
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.mobile_menu_bottom .menu__item img {
    width: 28px;
}

.mobile_menu_bottom .menu__item span {
    color: var(--main_text_black);
    font-size: 12px;
    display: block;
    margin-top: 5px;
}

.mobile_menu_bottom .menu__item.active span {
    color: var(--main_secondary);
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 9999;
    background-color: #fff;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -moz-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }
}

.fixed_bottom_menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 999;
    background-color: #fff;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, -100%);
        transform: translate3d(0, 0, -100%);
        -moz-transform: translate3d(0, 0, -100%);
        -ms-transform: translate3d(0, 0, -100%);
        -o-transform: translate3d(0, 0, -100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }
}


/* ******************************************square_header_css_End**************************************** */

.bdr-none {
    border: none !important;
}

.square_footer {
    background-color: #00a0e3;
}

.square_footer .footer_menu_row {
    background-color: #10498a;
    /* margin-top: 3px; */
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.square_footer .footer_menu_row2 {
    padding: 15px 0px;
    display: flex;
    justify-content: center;
}

.square_footer .footer_menu_row2 ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 0px;
    flex-wrap: wrap;
}

.square_footer .footer_menu_row2 ul li {
    margin-right: 30px;
}

.square_footer .footer_menu_row2 ul li:last-child {
    margin-right: 0px;
}

.square_footer .footer_menu_row .footer_menu_item ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 0px;
    flex-wrap: wrap;
}

.square_footer .footer_menu_row2 ul li a,
.square_footer .footer_menu_row .footer_menu_item ul li a {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
    padding: 0 5px;
}

.square_footer .footer_menu_row2 ul li a:hover,
.square_footer .footer_menu_row .footer_menu_item ul li a:hover {
    color: var(--color_bs_white);
}

.square_footer .footer_menu_row .footer_menu_item ul li ul {
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 20px;
}

.square_footer .footer_menu_row .footer_menu_item ul li ul li {
    text-align: l;
}

.square_footer .footer_menu_row .footer_menu_item ul li ul li a {
    text-align: left;
    font-size: 12px;
    padding: 7px 0;
    display: block;
}

.square_footer .footer_full_black {
    background-color: #10498a;
}

.square_footer .footer_full_black .get_in_touch {
    padding-top: 45px;
    position: relative;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    background-color: #10498a;
    position: absolute;
    left: 0;
    right: 0;
    top: 29px;
    width: 160px;
    margin: 0 auto;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row {
    display: flex;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 25px 20px 20px 20px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row {
    width: 300px;
    display: flex;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span {
    margin-right: 10px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span img {
    width: 40px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p {
    font-size: 14px;
    color: #fff !important;
    margin-bottom: 0;
    letter-spacing: 0;
    background: none !important;
    border: none;
    padding: 0px !important;
    font-weight: 300;
    line-height: 18px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
    width: calc(100% - 300px);
    display: flex;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
    padding: 0 15px;
    width: calc(100% - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a {
    font-size: 13px;
    color: #fff;
    border-left: 1px solid #fff;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 10px;
    font-weight: 300;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a img {
    width: 13px;
    margin-right: 5px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a:hover {
    color: #fff;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
    width: 200px;
    background: transparent;
    padding: 0px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media h3 {
    margin: 0;
    font-size: 18px;
    color: #fff;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media a img {
    width: 20px;
}

.square_footer .footer_full_black .footer_address {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.square_footer .footer_full_black .footer_address span {
    font-size: 12px;
}

.square_footer .footer_full_black .footer_address a {
    font-size: 12px;
    color: #fff;
    padding: 0px 10px;
    border-right: 1px solid #fff;
    line-height: normal;
    font-weight: 300;
}

.square_footer .footer_full_black .footer_address a:first-child,
.square_footer .footer_full_black .footer_address a:last-child {
    border-right: none;
}

.square_footer .footer_full_black .footer_address a:hover {
    color: #fff;
}

.square_footer .footer_full_black .copyright_block {
    text-align: center;
    border-top: 1px solid #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.square_footer .footer_full_black .copyright_block p {
    font-size: 10px;
    color: #c4c4c4 !important;
    margin-bottom: 0px;
    background: none !important;
    border: none;
    padding: 0px !important;
    line-height: 16px;
}

.offcanvas-device {
    display: block;
}

.offcanvas-device .contact-device {
    margin: 15px 0px;
    display: block !important;
}

.contact-device h5 {
    color: #10498a;
    font-weight: 600;
    font-size: 18px;
}

.offcanvas-device .contact-device ul.device-media {
    display: flex !important;
    flex-wrap: wrap;
}

.offcanvas-device .contact-device ul.device-media li {
    flex: 1;
    text-align: center;
    margin: 5px;
}

.offcanvas-device .contact-device ul.device-media li:first-child {
    margin-left: 0px;
}

.offcanvas-device .contact-device ul.device-media li:last-child {
    margin-right: 0px;
}

.offcanvas-device .contact-device ul.device-media li a {
    border: 1px solid var(--main_claim_back);
    padding: 10px;
    text-align: center;
    color: #111;
    font-size: 12px;
    font-weight: 400;
    display: block;
    border-radius: 10px;
    line-height: normal;
    text-transform: capitalize;
    height: 100%;
    line-height: 14px;
    cursor: pointer;
    background-color: #fff;
}

.offcanvas-device .contact-device ul.device-media li a img {
    width: 24px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.offcanvas-device .contact-device .header_footer_menu {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 9px;
}

.offcanvas-device .contact-device .header_footer_menu a {
    margin: 0 10px;
}

.offcanvas-device .contact-device .header_footer_menu a img {
    width: 120px;
}

.offcanvas-device .contact-device .support_call_number a {
    display: block;
    padding: 10px 0px;
    color: #111;
    font-size: 13px;
    border-bottom: 1px solid var(--main_claim_back);
}

.offcanvas-device .contact-device .support_call_number a:last-child {
    border-bottom: none;
}

.offcanvas-device .contact-device .support_call_number a img {
    width: 20px;
    margin-right: 10px;
}

/* ******************************************square_footer_css_End**************************************** */

@media (max-width:1199px) {
    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media a {
        margin: 0 9px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media span {
        margin-top: 0;
        margin-left: 0px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
        width: 100%;
        justify-content: flex-end;
        padding-right: 0;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
        flex-wrap: wrap;
    }

    .square_footer .footer_menu_row2 ul li a,
    .square_footer .footer_menu_row .footer_menu_item ul li a {
        font-size: 14px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .square_footer .footer_menu_row2 ul li {
        margin-right: 0px;
        line-height: 16px;
    }

    .square_footer .footer_menu_row2 ul li a,
    .square_footer .footer_menu_row .footer_menu_item ul li a {
        padding: 0 0px;
    }

    .square_footer .footer_menu_row2 ul {
        align-items: flex-start;
    }

    .square_footer .footer_menu_row {
        padding: 10px 15px;
    }

    .home_blog {
        margin-top: 30px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_title {
        font-size: 20px;
        font-weight: 500;
        top: 23px;
        width: 150px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
        font-weight: 500;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span img {
        width: 24px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row {
        align-items: center;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row {
        border: 1px solid #fff;
        padding: 20px 20px 15px 20px;
    }

    .square_footer .footer_menu_row2 ul li a,
    .square_footer .footer_menu_row .footer_menu_item ul li a {
        line-height: 14px;
    }

    .square_footer .footer_menu_row2 ul li,
    .square_footer .footer_menu_row .footer_menu_item ul li {
        line-height: 24px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
        margin: 10px 0px;
    }

    .square_footer .footer_full_black .get_in_touch {
        padding-top: 35px;
    }
}

@media (max-width:991px) {

    .square_header .header_static .main_menu_inner .top_list_menu {
        display: none;
    }

    .square_header .header_static .main_menu_inner .main_menu nav>ul>li>a small.down_icon {
        float: right;
        top: 4px
    }

    .square_header .menubar_row .logo {
        width: 140px;
    }

    .square_header .menubar_row .all_menu_block {
        width: calc(100% - 140px);
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a {
        font-size: 12px;
        padding: 2px 8px;
    }

    .square_header .menubar_row .all_menu_block .top_list_menu {
        margin-bottom: 8px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block .megamenu-btn-align {
        position: relative;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .mega-menu-part p {
        background-color: transparent !important;
        font-size: 12px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-block .megamenu-btn-align a {
        font-size: 13px;
        margin-right: 5px;
        padding: 4px 10px;
        font-weight: 400;
    }

    .square_footer .footer_menu_row .footer_menu_item ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .square_footer .footer_menu_row2 ul {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .square_footer .footer_menu_row2 ul li {
        margin-right: 0px;
    }

    .square_footer .footer_menu_row2 ul li a,
    .square_footer .footer_menu_row .footer_menu_item ul li a {
        font-size: 12px;
        padding: 0 10px;
        border-right: 1px solid #fff;
    }

    .square_footer .footer_menu_row2 ul li:last-child a,
    .square_footer .footer_menu_row .footer_menu_item ul li:last-child a {
        border-right: none;
    }

    .square_footer .footer_menu_row2 {
        padding: 10px 0px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row {
        flex-wrap: wrap;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row,
    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
        width: 100%;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details,
    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
        justify-content: flex-start;
        padding-left: 0;
    }

    .square_header .menubar_row .all_menu_block .menu_row .login_box button {
        width: 90px;
    }

    .square_header .menubar_row .all_menu_block .menu_row .login_box {
        margin-left: 15px;
    }

    .square_header .header_static .main_menu_inner .main_menu {
        width: auto;
    }

    .square_header .header_static .main_menu_inner {
        width: auto;
    }

    .square_header .header_static .logo_section {
        text-align: center;
        margin: 0 auto;
    }

    .square_header .header_static {
        display: flex;
        position: relative;
        width: 100%;
        background: #fff;
        top: 0;
        padding: 7px 0px;
        left: 0px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p {
        padding: 8px 0px 8px 10px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul {
        margin: 0px;
        padding: 0px 10px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul.device-media {
        padding: 0px;
    }

    .square_header .header_static .main_menu_inner .main_menu #man_menu_show_hide {
        display: none;
        width: 100%;
        position: fixed;
        height: calc(100vh - 51px);
        background: #fff;
        left: 0px;
        max-width: 100%;
        box-shadow: none;
        border-right: none;
        top: 50px;
        z-index: 9999;
        overflow-y: auto;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul {
        display: block;
        overflow-y: auto;
    }

    .square_header .header_static .main_menu_inner .main_menu nav>ul>li>a {
        padding: 10px 10px 10px 0px;
        font-size: 16px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight {
        border: none;
        border-radius: 0;
        background: #00a0e34a;
        display: inline-block;
        border-radius: 5px;
        color: #111;
        padding: 5px 15px;
        margin-top: 10px
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu>ul {
        width: 100%;
        display: block;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p {
        border: none;
        border-radius: 0;
        margin-bottom: 0;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p {
        width: 100%;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu {
        width: 100%;
        display: none;
        flex-direction: column;
        position: relative;
        padding: 0px 10px 0px 10px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu>ul li {
        width: 100%;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu>ul {
        max-height: 100%;
        border-left: 1px solid var(--main_claim_back);
        margin: 0px 10px;
        width: auto;
        padding: 0px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active p small.plus_down_minus_icon,
    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover p small.plus_down_minus_icon {
        color: #000;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p small.plus_down_minus_icon {
        width: auto;
        height: auto;
        transform: rotate(0deg);
        top: 0;
        font-size: 18px;
        border: none;
        display: block;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu.block-view {
        display: inherit;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active .shop-mega-menu,
    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover .shop-mega-menu {
        display: none;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn {
        display: block;
        position: absolute;
        left: 5px;
        background: #fff;
        -webkit-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
        -moz-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
        box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
        text-align: center;
        margin: 0px auto;
        border-radius: 8px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        top: 7px;
        z-index: 9999;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn img {
        width: 25px;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon {
        height: 0;
        background: transparent;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:before {
        top: 0 !important;
        -webkit-transform: rotate(-45deg) !important;
        transform: rotate(-45deg) !important;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:after {
        top: 0 !important;
        -webkit-transform: rotate(45deg) !important;
        transform: rotate(45deg) !important;
    }

    .square_header .header_static .main_menu_inner #menu_open_btn:hover #man_menu_show_hide {
        height: 0;
        background: transparent;
    }

    #main-menu-state:checked~.main-menu-btn .main-menu-btn-icon {
        height: 0;
        background: transparent;
    }

    .square_header {
        background-color: #fff;
        width: 100%;

    }

    .square_home_page_css .home_banner_part_block {
        background-color: #fff;

    }

    .square_header .header_static .main_menu_inner .main_menu .login_box {
        margin-left: 0;
        margin-right: 15px;
    }

    .square_header .header_static .main_menu_inner .main_menu .login_box button {
        width: auto;
        background-image: none;
        height: auto;
        color: #fff;
        border-radius: 0;
        background: #00a0e300;
        color: #000;
        margin-right: 0px;
        border: 1px solid #00a0e3;
        font-size: 14px;
        border-radius: 5px;
        padding: 1px 12px;
    }

    .square_header .header_static .header-mobile {
        display: flex;
    }

    .square_header .header_static .header-mobile .login-demo .login_box button.login_popup_cs {
        background-image: linear-gradient(110deg, #00a0e3 0%, #10498a 100%);
        float: right;
        color: #fff;
        padding: 3px 15px !important;
        border-radius: 3px;
        margin-top: 10px;
        margin-right: 10px;
        height: auto !important;
        width: inherit !important;
    }

    .square_header .header-mobile {
        display: flex;
        padding: 5px 0px;
        margin-bottom: 20px;
    }

    .square_header .header-mobile .logo {
        text-align: center;
        margin: 0 auto;
    }

    .square_header .header_static .logo_section img {
        width: 100px;
    }

    .square_header .header-mobile .logo img {
        width: 100px;
    }




    .square_header .header_static .main_menu_inner .main_menu nav>ul>li {
        display: block;
    }

    .square_header .header_static .main_menu_inner .main_menu nav>ul>li.menu-view {
        display: block;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li:hover .mega_menu,
    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu {
        position: relative;
        min-width: 100%;
        padding: 0;
        background: #fff;
        box-shadow: none;
        border-radius: 4px;
        left: 0;
        right: unset;
        opacity: 1;
        visibility: visible;
        -webkit-transition: 0.15s;
        transition: 0.15s;
        z-index: 9;
        top: unset !important;
        display: none;
        height: 100%;
        border: none;
        min-height: auto;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li p a {
        font-size: 13px;
        font-weight: 400;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li:hover .sub_menu,
    .square_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu {
        position: relative;
        min-width: 100%;
        padding: 10px;
        box-shadow: none;
        border-radius: 0;
        left: inherit;
        right: inherit;
        opacity: 1;
        visibility: visible;
        top: unset !important;
        display: none;
        display: none;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li {
        width: calc(100% / 2);
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li.active p,
    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li:hover p {
        background: #fff;
        border-color: #fff;
        text-align: justify;
    }

    .color-shadow-blocks {
        display: none;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-part {
        margin: 5px 5px;
        border-left: 1px solid var(--main_claim_back);
        padding-left: 10px;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category li .shop-mega-menu .mega-menu-part h2 {
        font-size: 15px;
    }

    .contact-device {
        margin: 15px;
        display: block !important;
    }

    .contact-device h5 {
        color: #10498a;
        font-weight: 600;
        font-size: 20px;
    }

    .contact-device ul.device-media {
        display: flex !important;
        flex-wrap: wrap;
    }

    .contact-device ul.device-media li {
        flex: 1;
        text-align: center;
        margin: 5px;
    }

    .contact-device ul.device-media li:first-child {
        margin-left: 0px;
    }

    .contact-device ul.device-media li:last-child {
        margin-right: 0px;
    }

    .contact-device ul.device-media li a {
        border: 1px solid var(--main_claim_back);
        padding: 10px 5px;
        text-align: center;
        color: #111;
        font-size: 13px;
        font-weight: 400;
        display: block;
        border-radius: 5px;
        line-height: normal;
        /* box-shadow: 0 6px 16px rgba(52,105,203,.16); */
        text-transform: capitalize;
        height: 100%;
    }

    .contact-device ul.device-media li a img {
        width: 24px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .contact-device .support_call_number a {
        display: block;
        padding: 10px 0px;
        color: #111;
        font-size: 14px;
        border-bottom: 1px solid var(--main_claim_back);
    }

    .contact-device .support_call_number a:last-child {
        border-bottom: none;
    }

    .contact-device .support_call_number a img {
        width: 20px;
        margin-right: 10px;
    }

}

.square_header .contact-device .header_footer_menu {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.square_header .contact-device .header_footer_menu a {
    margin: 0 10px;
}

.square_header .contact-device .header_footer_menu a img {
    width: 120px;
    background: #10498a;
}

@media (max-width:768px) {
    .square_header .menubar_row .all_menu_block .menu_row ul {
        display: none;
    }

    .square_header .header_static .main_menu_inner .main_menu nav ul li ul.mega_menu .shop-menu .shop-category>li .shop-mega-menu .mega-menu-part>ul li {
        width: calc(100% / 1);
    }

    .square_header .menubar_row .all_menu_block .top_list_menu {
        display: none;
    }

    .square_header .menubar_row .logo,
    .square_header .menubar_row .logo img {
        width: 100px;
    }

    .square_header .menubar_row .all_menu_block {
        width: calc(100% - 100px);
    }

    .square_home_page_css .home_banner_part_block .third_party_products {
        display: none;
    }

    .square_header .menubar_row .all_menu_block .top_list_menu a {
        font-size: 10px;
        margin-left: 10px;
    }

    .square_header .menubar_row .all_menu_block .top_list_menu {
        margin-bottom: 5px;
    }

    .mobile_menu_bottom.fixed_bottom_menu {
        display: flex;
    }

    .square_footer .footer_menu_row2 ul {
        flex-wrap: wrap;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details,
    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
        flex-wrap: wrap;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a {
        padding-left: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0px;
        display: block;
        width: 100%;
        border-left: none;
    }

    /* .square_footer {
        padding-bottom: 60px;
    } */

    .square_footer .footer_full_black .footer_address a {
        font-size: 10px;
    }

    .square_footer .footer_full_black .copyright_block p {
        font-size: 8px;
        margin: 0px;
        line-height: 12px;
    }

    .square_footer .footer_full_black .copyright_block {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .mobile_menu_bottom {
        padding: 6px 15px;
    }

    .square_header .menubar_row .all_menu_block {
        width: auto;
    }

    .square_header .menubar_row .all_menu_block .menu_row .login_box {
        margin-left: 0px;
    }

    .square_header .menubar_row .mobile_menu_open {
        display: block;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        left: 0;
        top: 0;
        z-index: 999999;
        overflow-y: auto;
        padding-top: 10px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu {
        display: flex;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist {
        display: block;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div li .cs_dropdown_menu {
        position: relative;
        width: 100%;
        height: auto;
        top: 0;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        padding: 10px 10px 10px 30px;
        box-shadow: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu {
        position: relative !important;
        width: 100%;
        top: 0;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner ul {
        /* display: block; */
        width: 100%;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner ul li a .svg-inline--fa {
        display: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner ul li a:hover .tabcontent {
        display: block
    }

    .square_header .menubar_row .all_menu_block .menu_row>ul>li {
        padding-left: 20px;
    }

    .square_header.fixed {
        display: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a {
        font-size: 14px;
        padding: 10px 8px;
        font-weight: 700;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul>li {
        border-bottom: 0px solid #ddd;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul>li:last-child {
        border: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner {
        display: block;
        padding: 0px;
        background-color: #fff;
        border-top: 0px solid #ddd;
        border-bottom: 0px solid #ddd;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a span.msow {
        display: none;
        margin-right: 10px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner li a img {
        display: none;
    }

    .square_footer .footer_menu_row {
        padding: 10px;
    }

    .square_footer .footer_menu_row .footer_menu_item ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div li .cs_dropdown_menu {
        padding: 0;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner li a {
        padding: 7px 10px !important;
        margin: 0px;
        font-size: 13px;
        border: none;
        border-radius: 0px;
    }

    .square_header .menubar_row .all_menu_block .menu_row>ul>li {
        padding-left: 0;
        margin: 0px 10px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li.products_menu:hover .cs_dropdown_menu {
        border: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a {
        padding: 6px 10px;
        font-size: 14px;
        font-weight: 500;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner li a {
        font-size: 12px;
        margin: 0px;
        font-weight: 400;
    }

    .d-block-desktop {
        display: inherit;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner .tabcontent {
        width: 100%;
        padding: 0;
        display: none;
        position: relative;
        height: auto;
        margin: 15px;
        position: absolute;
        /* z-index: 999999999; */
        left: 0;
        right: 0;
        top: 50px;
        background: #fff;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        margin: 0px;
    }

    .tabs_menu_inner .mega-menu-ul .megamenu-heading a.viewall {
        padding: 3px 7px !important;
        background-color: var(--main_claim_back);
        border-radius: 5px !important;
        font-size: 13px !important;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner .tabcontent h3 {
        font-size: 13px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 500;
        letter-spacing: 0px;
        width: calc(100%/1) !important;
        border-bottom: 1px solid var(--main_claim_back);
        padding: 5px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner .tabcontent .mega-menu-ul a {
        margin: 0px;
        padding: 0;
        color: #3c3c3c;
        font-size: 13px;
        font-weight: 400;
        border: none;
        display: inline-block;
        letter-spacing: 0px;
        position: relative;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li.products_menu:hover .cs_dropdown_menu {
        display: inherit;
        visibility: visible;
        opacity: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li.products_menu:hover .cs_dropdown_menu .tabs_menu_inner>ul {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .tabcontent {
        display: none;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner li:hover a+.tabcontent {
        display: block !important;
    }

    .square_home_page_css .our_insurance_companies .our_company_data ul li {
        width: calc(100%/7);
    }

    .square_footer .footer_menu_row2 ul li a,
    .square_footer .footer_menu_row .footer_menu_item ul li a {
        font-size: 10px;
        padding: 0 10px;
        border-right: 1px solid #fff;
    }

    .square_footer .footer_menu_row2 ul li:last-child a,
    .square_footer .footer_menu_row .footer_menu_item ul li:last-child a {
        border-right: none;
    }

    .square_footer .footer_menu_row2 ul li {
        margin-right: 0px;
        line-height: 20px;
    }

    .square_footer .footer_menu_row2 ul {
        justify-content: center;
    }
}

@media (max-width:576px) {
    .square_header .menubar_row .all_menu_block .menu_row .login_box button {
        height: 30px;
        padding: 0 10px;
        font-weight: 400;
        width: 75px;
        font-size: 14px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span {
        font-size: 16px;
        margin-right: 10px;
    }

    .cs_main_insurance_products_inner .cs_scrol_box .home_products_block ul li {
        width: calc(100% / 3 - 5px);
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a,
    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .support_call_number a {
        font-size: 15px;
        font-weight: 500;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box a {
        font-size: 13px;
        font-weight: 600;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .hm_header_title,
    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu {
        margin-bottom: 10px;
    }

    .square_header .menubar_row .all_menu_block .menu_row>ul>li {
        padding-left: 0px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .inner_header_menu {
        padding: 0 10px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a {
        padding: 6px 3px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li a span.msow img {
        width: 26px;
    }

    .square_header .menubar_row .all_menu_block .menu_row>ul>li a.down_icon::after {
        bottom: 18px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div li .cs_dropdown_menu {
        padding: 0px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul li .cs_dropdown_menu.tabs_products_menu .tabs_menu_inner li a {
        padding: 7px 40px 7px 7px !important;
        font-size: 13px;
        margin: 0px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .mobile_menu_a_box a img {
        width: 26px;
    }

    .square_header .menubar_row .all_menu_block .menu_row ul.mobile_menu_div .contact_menu_mobile_navlist .support_call_number {
        margin-top: 10px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span img {
        width: 30px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
        font-weight: 500;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row {
        border: 1px solid #fff;
        padding: 18px 15px 15px 15px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media h3 {
        font-size: 16px;
    }

    .square_footer .footer_full_black .footer_address {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media a img {
        width: 16px;
    }

    .square_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
        justify-content: space-between
    }

    .contact-device ul.device-media li a {
        line-height: 14px;
        font-size: 12px;
    }

    .contact-device .support_call_number a {
        padding: 7px 0px;
        font-size: 13px;
    }

    .contact-device ul.device-media li {
        margin: 3px;
    }
}

@media (max-width:495px) {
    .main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login {
        height: 260px;
        flex-direction: column;
    }

    #login_popup_main.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
        max-height: 360px;
    }
}

@media only screen and (min-width: 421px) and (max-width: 505px) {
    .main_popup.cs_main_insurance_products .cs_main_insurance_products_inner .cs_scrol_box .home_products_block a {
        width: calc(100% / 4 - 6px);
    }

    .main_popup.cs_main_insurance_products .cs_main_insurance_products_inner .cs_scrol_box .home_products_block a span img {
        display: inline;
    }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .main_popup.cs_main_insurance_products .cs_main_insurance_products_inner .cs_scrol_box .home_products_block a {
        width: calc(100% / 3 - 6px);
    }

    .main_popup.cs_main_insurance_products .cs_main_insurance_products_inner .cs_scrol_box .home_products_block a span img {
        width: 40px;
        display: inline;
    }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
    .mobile_menu_bottom .menu__item span {
        font-size: 11px;
    }
}

.menu-align {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-align .menu-manage {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.menu-align .login_box button {
    border: none;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
}


.main-nav .logo img {
    width: 150px;
}

.menu-align .top_list_menu {
    display: flex;
    margin-bottom: 5px;
    justify-content: end;
}

.menu-align .top_list_menu a {
    font-size: 13px;
    margin-left: 20px;
    color: var(--main_text);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-align .top_list_menu a:first-child {
    margin-left: 0;
}

.menu-align .top_list_menu img {
    width: 16px;
    margin-right: 6px;
}

.menu-manage ul.sm-blue li ul li a span img {
    width: 30px;
    margin-right: 10px;
}

.tabs-menu .menu-manage .tabs-menu-in h2 {
    margin: 0px;
    line-height: 20px;
}

.tabs-menu .menu-manage .tabs-menu-in h2 a {
    margin: 0px !important;
    color: #084298 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
    letter-spacing: 0px !important;
    padding: 0px !important;
}

.tabs-menu .menu-manage .tabs-menu-in .tabs-menu-details h3 {
    font-size: 16px;
    margin-top: 0;
    color: #10498a;
    margin-bottom: 10px;
    font-weight: 600;
    letter-spacing: 0px;
    width: calc(100%/3) !important;
}

.tabs-menu .menu-manage .tabs-menu-in .tabs-menu-details h3 a {
    margin: 0px;
    padding: 4px 0px 4px 0px;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    border: none;
    display: inline-block;
    letter-spacing: 0px;
    position: relative;
}

.sm-blue a.disabled {
    color: #9d9cbf;
}

.sm-blue a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 4px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
}

.sm-blue a .sub-arrow::before {
    content: '+';
}

.sm-blue a.highlighted .sub-arrow::before {
    content: '-';
}

.sm-blue>li:first-child>a,
.sm-blue>li:first-child> :not(ul) a {
    border-radius: 0px 0px 0 0;
}

.sm-blue>li:last-child>a,
.sm-blue>li:last-child>*:not(ul) a,
.sm-blue>li:last-child>ul,
.sm-blue>li:last-child>ul>li:last-child>a,
.sm-blue>li:last-child>ul>li:last-child>*:not(ul) a,
.sm-blue>li:last-child>ul>li:last-child>ul,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
    border-radius: 0 0 0px 0px;
}

.sm-blue>li:last-child>a.highlighted,
.sm-blue>li:last-child>*:not(ul) a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.sm-blue>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted {
    border-radius: 0;
}

.sm-blue ul {
    background: #fff;
}

.sm-blue ul ul {
    background: rgba(102, 102, 102, 0.1);
}

.sm-blue ul a,
.sm-blue ul a:hover,
.sm-blue ul a:focus,
.sm-blue ul a:active {
    background: transparent;
    color: #10498a;
    font-size: 16px;
    text-shadow: none;
    border-left: 8px solid transparent;
}

.sm-blue ul a.current {
    color: var(--main_text_black);
    background-color: var(--main_claim_back);
    border: 1px solid var(--main_claim_back);
}

.sm-blue ul a.disabled {
    color: #b3b3b3;
}

.sm-blue ul ul a,
.sm-blue ul ul a:hover,
.sm-blue ul ul a:focus,
.sm-blue ul ul a:active {
    border-left: 16px solid transparent;
}

.sm-blue ul ul ul a,
.sm-blue ul ul ul a:hover,
.sm-blue ul ul ul a:focus,
.sm-blue ul ul ul a:active {
    border-left: 24px solid transparent;
}

.sm-blue ul ul ul ul a,
.sm-blue ul ul ul ul a:hover,
.sm-blue ul ul ul ul a:focus,
.sm-blue ul ul ul ul a:active {
    border-left: 32px solid transparent;
}

.sm-blue ul ul ul ul ul a,
.sm-blue ul ul ul ul ul a:hover,
.sm-blue ul ul ul ul ul a:focus,
.sm-blue ul ul ul ul ul a:active {
    border-left: 40px solid transparent;
}

.sm-blue ul li:first-child {
    border-top: 0;
}

.desktop-hide {
    display: none;
}

.menu-manage ul.sm-blue li.contact_menu_mobile_navlist {
    display: none;
}


@media (min-width: 768px) {
    .sm-blue ul {
        position: absolute;
        width: 12em;
    }

    .sm-blue li {
        float: left;
    }

    .sm-blue.sm-rtl li {
        float: right;
    }

    .sm-blue ul li,
    .sm-blue.sm-rtl ul li,
    .sm-blue.sm-vertical li {
        float: none;
    }

    .sm-blue a {
        white-space: nowrap;
    }

    .sm-blue ul a,
    .sm-blue.sm-vertical a {
        white-space: normal;
    }

    .sm-blue .sm-nowrap>li>a,
    .sm-blue .sm-nowrap>li> :not(ul) a {
        white-space: nowrap;
    }

    .sm-blue a .sub-arrow::before {
        display: none;
    }

    .sm-blue>li:first-child>a,
    .sm-blue>li:first-child> :not(ul) a {
        border-radius: 0px 0 0 0px;
    }

    .sm-blue>li:last-child>a,
    .sm-blue>li:last-child> :not(ul) a {
        border-radius: 0 0px 0px 0 !important;
    }

    .sm-blue>li {
        border-left: 0px solid #434266;
    }

    .sm-blue>li:first-child {
        border-left: 0;
    }

    .sm-blue ul ul {
        border-radius: 0px !important;
        background: #fff;
    }

    .sm-blue ul a,
    .sm-blue ul a:hover,
    .sm-blue ul a:focus,
    .sm-blue ul a:active,
    .sm-blue ul a.highlighted {
        border: 0 !important;
        padding: 10px 23px;
        background: transparent;
        color: #10498a;
        border-radius: 0 !important;
    }

    .sm-blue ul a:hover,
    .sm-blue ul a:focus,
    .sm-blue ul a:active,
    .sm-blue ul a.highlighted {
        color: var(--main_text_black);
        background-color: var(--main_claim_back);
        border: 1px solid var(--main_claim_back);
    }

    .sm-blue ul a.current {
        color: var(--main_text_black);
        background-color: var(--main_claim_back);
        border: 1px solid var(--main_claim_back);
    }

    .sm-blue ul a.disabled {
        background: #fff;
        color: #b3b3b3;
    }

    .sm-blue ul a .sub-arrow {
        top: 50%;
        margin-top: -5px;
        bottom: auto;
        left: auto;
        margin-left: 0;
        right: 10px;
        border-style: dashed dashed dashed solid;
        border-color: transparent transparent transparent #dadae7;
    }

    .sm-blue ul li {
        border: 0;
    }


    .sm-blue .scroll-down-arrow {
        top: 6px;
        border-style: solid dashed dashed dashed;
        border-color: #10498a transparent transparent transparent;
    }

    .sm-blue.sm-rtl.sm-vertical a .sub-arrow {
        right: auto;
        left: 10px;
        border-style: dashed solid dashed dashed;
        border-color: transparent #dadae7 transparent transparent;
    }

    .sm-blue.sm-rtl>li:first-child>a,
    .sm-blue.sm-rtl>li:first-child> :not(ul) a {
        border-radius: 0 0px 0px 0;
    }

    .sm-blue.sm-rtl>li:last-child>a,
    .sm-blue.sm-rtl>li:last-child> :not(ul) a {
        border-radius: 0px 0 0 0px !important;
    }

    .sm-blue.sm-rtl>li:first-child {
        border-left: 0px solid #434266;
    }

    .sm-blue.sm-rtl>li:last-child {
        border-left: 0;
    }

    .sm-blue.sm-rtl ul a .sub-arrow {
        right: auto;
        left: 10px;
        border-style: dashed solid dashed dashed;
        border-color: transparent #dadae7 transparent transparent;
    }

    .sm-blue.sm-vertical {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }

    .sm-blue.sm-vertical a {
        padding: 9px 23px;
    }

    .sm-blue.sm-vertical a .sub-arrow {
        top: 50%;
        margin-top: -5px;
        bottom: auto;
        left: auto;
        margin-left: 0;
        right: 10px;
        border-style: dashed dashed dashed solid;
        border-color: transparent transparent transparent #dadae7;
    }

    .sm-blue.sm-vertical>li:first-child>a,
    .sm-blue.sm-vertical>li:first-child> :not(ul) a {
        border-radius: 0px 0px 0 0;
    }

    .sm-blue.sm-vertical>li:last-child>a,
    .sm-blue.sm-vertical>li:last-child> :not(ul) a {
        border-radius: 0 0 0px 0px !important;
    }

    .sm-blue.sm-vertical>li {
        border-left: 0 !important;
    }

    .sm-blue.sm-vertical ul {
        border-radius: 0px !important;
    }

    .sm-blue.sm-vertical ul a {
        padding: 10px 23px;
    }
}


.main-nav {
    box-shadow: 0 0 10px 0 #dfdfdf;
    position: relative;
    z-index: 999;
    padding: 5px 0px;
}

.main-nav:after {
    clear: both;
    content: "\00a0";
    display: block;
    height: 0;
    font: 0px/0 serif;
    overflow: hidden;
}

.nav-brand {
    float: left;
    margin: 0;
}

.nav-brand a {
    display: block;
    padding: 10px 10px 10px 20px;
    color: #fff;
    font-size: 22px;
    font-weight: normal;
    line-height: 29px;
    text-decoration: none;
}

#main-menu {
    clear: both;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

@media (min-width: 768px) {
    #main-menu {
        float: right;
        clear: none;
        width: 72%;
    }
}

@media (min-width: 768px) {
    #main-menu:before {
        display: none;
    }
}

.main-menu-btn {
    float: right;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 25px;
    text-indent: 25px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
    position: absolute;
    top: 50%;
    left: 8px;
    height: 1px;
    width: 20px;
    background: #262525;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    z-index: 9999;
}

.main-menu-btn-icon {
    width: 14px;
}

.main-menu-btn-icon:before {
    content: '';
    top: -8px;
    left: 0;
}

.main-menu-btn-icon:after {
    content: '';
    top: 8px;
    left: 0;
}

#main-menu-state:checked~.main-menu-btn .main-menu-btn-icon {
    height: 0;
    background: transparent;
}

#main-menu-state:checked~.main-menu-btn .main-menu-btn-icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#main-menu-state:checked~.main-menu-btn .main-menu-btn-icon:after {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#main-menu-state {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

#main-menu-state:not(:checked)~#main-menu {
    display: none;
}

#main-menu-state:checked~#main-menu {
    display: block;
}

#main-menu,
#main-menu>.has-mega-menu {
    position: static !important;
}

#main-menu .mega-menu {
    margin-left: 0 !important;
    right: 0 !important;
    width: auto !important;
    max-width: none !important;
}

#main-menu>.has-mega-menu .scroll-up,
#main-menu>.has-mega-menu .scroll-down {
    margin-left: 0 !important;
}

.sm-blue ul li:first-child {
    border-top: 0;
}

#main-menu .mega-menu>li {
    overflow: auto;
}

.sm-blue ul li,
.sm-blue.sm-rtl ul li,
.sm-blue.sm-vertical li {
    float: none;
}

#main-menu .mega-menu .column-1-3 {
    float: left;
    width: 33.33%;
}

@media (min-width: 768px) {
    .main-menu-btn {
        position: absolute;
        top: -99999px;
    }

    #main-menu-state:not(:checked)~#main-menu {
        display: block;
    }
}


@media (max-width: 767px) {
    .main-nav .logo a {
        text-align: center;
        margin: 0 auto;
    }

    .menu-align .top_list_menu {
        display: none;
    }

    .menu-align .menu-manage {
        display: inline;
        text-align: left;
    }

    .sm-blue ul ul {
        max-width: 100% !important;
        width: 100% !important;
    }

    .sm-blue li ul ul li .tabs-menu {
        width: 100% !important;
    }

    ul.sm.sm-blue li>ul {
        margin-left: 0px !important;
    }

    ul.sm.sm-blue li>ul {
        margin-left: 0px !important;
        margin-top: 0px !important;
        max-width: 100% !important;
    }

    ul.sm.sm-blue li>ul>li a {
        color: #333;
        font-size: 13px;
        border-left: 1px solid var(--main_claim_back);
        font-weight: 400;
        padding-left: 10px;
    }

    ul.sm.sm-blue li>ul>li a span img {
        width: 25px;
        margin-right: 8px;
    }

    .sm-blue li ul ul li .tabs-menu {
        width: 100% !important;
        padding-left: 15px;
        border-left: 1px solid var(--main_claim_back);
    }

    ul.sm-blue li ul ul li .tabs-menu .tabs-menu-in .tabs-menu-details {
        display: inline !important;
    }

    .sm-blue li ul ul li .tabs-menu .tabs-menu-in .tabs-menu-details h3 a {
        color: #333;
        font-size: 13px;
        border-left: 1px solid var(--main_claim_back);
        font-weight: 400;
        padding-left: 15px;
    }

    ul.sm-blue ul ul {
        background: #fff;
        max-width: 100% !important;
        width: 100% !important;
    }

    ul.sm-blue li ul ul li .tabs-menu .tabs-menu-in {
        padding: 10px 0px 0px 0px !important;
        border-bottom: none !important;
    }

    ul.sm-blue li ul ul li .tabs-menu .tabs-menu-in .tabs-menu-details h3 a {
        margin: 0px;
        padding: 10px 0px !important;
        color: #333;
        font-size: 13px;
        font-weight: 400;

    }

    .sm-blue li ul ul li .tabs-menu .tabs-menu-in h2 a {
        margin-bottom: 0px !important;
    }

    .main-nav .logo img {
        width: 100px;
    }

    .main-nav {
        box-shadow: none;
    }

    .menu-align .login_box button {
        height: inherit;
        padding: 0;
        font-weight: 400;
        color: #111;
        letter-spacing: 0px;
        border: none !important;
        font-weight: 400;
        font-size: 12px;
    }

    .custom-btn {
        width: auto;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        outline: none;
        background-image: none;
        background-color: transparent;
    }

    .custom-btn {
        width: auto;
    }

    .boxes-menu img {
        width: 22px;
    }

    .desktop-hide {
        display: block;
    }

    .mobile-hide {
        display: none;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist {
        display: block;
        margin-top: 15px;
        border: none;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .hm_header_title {
        font-size: 18px;
        font-weight: 600;
        color: #03a9f4;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .hm_header_title img {
        width: 23px;
        margin-right: 7px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .mobile_menu_a_box {
        display: flex;
        justify-content: space-between;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .mobile_menu_a_box a {
        border: 2px solid var(--main_claim_back);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: calc(100% / 3 - 10px);
        color: #3c3c3c;
        font-size: 14px;
        text-align: center;
        padding: 10px 10px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .mobile_menu_a_box a {
        font-size: 13px;
        font-weight: 600;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .mobile_menu_a_box a img {
        width: 26px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .mobile_menu_a_box a span {
        display: block;
        margin-top: 8px;
        line-height: 16px;
        font-weight: 400;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .support_call_number a {
        font-size: 14px;
        padding: 7px 0;
        color: #3c3c3c;
        border: none;
        display: flex;
        align-items: center;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .support_call_number a img {
        width: 20px;
        margin-right: 7px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .header_footer_menu {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .header_footer_menu a {
        margin: 0 10px;
    }

    .menu-manage ul.sm-blue li.contact_menu_mobile_navlist .header_footer_menu a img {
        width: 145px;
        background: #10498a;
        border-radius: 5px;
    }

    #main-menu-state:checked~#main-menu {
        display: block;
        border-bottom: 1px solid var(--main_claim_back);
        margin: 0px 15px;
    }

    .menu-manage {
        border-bottom: 1px solid var(--main_claim_back);
    }

    .mobile-show {
        width: auto;
        display: inline-block !important;
        text-align: center;
        float: right;
    }

    .menu-align .login_box {
        margin-right: 0px;
    }

    .main-nav {
        padding: 10px 0px;
    }

    .boxes-menu {
        display: none;
    }

    .main-menu-btn {
        float: left;
    }

    .menu-align {
        position: relative;
        display: inline-block;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: center;
    }
    #quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer{
        flex-direction: column;
    }
    #quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_media{
        display: none;
    }
    #quote_products.main_custom_popup .main_custom_popup_inner .pat_insurance_outer .pet_content_media {
        width: 100%;
        padding-left: 0px;
    }

}